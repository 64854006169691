const plantsdb = [
    ['Plum', 'Prunus domestica', 'plum.jpg', 'Photo by PROPOLI87', 'https://commons.wikimedia.org/wiki/File:Fiori_di_Prugno_in_marzo_(Prunus_domestica).jpg'],
    ['Pear', 'Pyrus communis', 'pear.jpg', 'Photo by Jacek Halicki', 'https://commons.wikimedia.org/wiki/File:2015_Kwiatostan_gruszy_pospolitej.jpg'],
    ['Pear', 'Pyrus communis', 'pear2.jpg', 'Photo by Ввласенко', 'https://commons.wikimedia.org/wiki/File:Common_pear_(Pyrus_communis)_blooming.jpg'],
    ['Walnut', 'Juglans regia', 'walnut.jpg', 'Photo by Martinus KE', 'https://commons.wikimedia.org/wiki/File:Juglans_regia_(juv.)_-_EBE_Jesuitengasse_-_2021-09-30_-_250b.jpg'],
    ['Sweet Chestnut', 'Castanea sativa', 'sweet-chestnut.jpg', 'Photo by Tony Rebelo', 'https://commons.wikimedia.org/wiki/File:Castanea_sativa_108978772.jpg'],
    ['Oak', 'Quercus Spp.', 'oak.jpg', 'Photo by David J. Stang', 'https://commons.wikimedia.org/wiki/File:Quercus_phellos_3zz.jpg'],
    ['Horse Chestnut', 'Aesculus hippocastanum', 'horse-chestnut.jpg', 'Photo by NasserHalaweh', 'https://commons.wikimedia.org/wiki/File:Sapindaceae_Aesculus_hippocastanum_1.jpg'],
    ['Rowan', 'Sorbus aucuparia', 'rowan.jpg', 'Photo by Krzysztof Ziarnek', 'https://commons.wikimedia.org/wiki/File:Sorbus_aucuparia_kz13.jpg'],
    ['English Yew', 'Taxus baccata', 'english-yew.jpg', 'Photo by Krzysztof Ziarnek', 'https://commons.wikimedia.org/wiki/File:Taxus_baccata_%27Semperaurea%27_kz03.jpg'],
    ['Irish Yew', "Taxus baccata 'Fastigiata'", 'irish-yew.jpg', 'Photo by I.Sáček', 'https://commons.wikimedia.org/wiki/File:Taxus_baccata_10600.JPG'],
    ['Hornbeam', 'Carpinus betulus', 'hornbeam.jpg', 'Photo by David J. Stang, CC BY-SA 4.0', 'https://commons.wikimedia.org/wiki/File:Carpinus_betulus_33zz.jpg'],
    ['Scots Pine', 'Pinus sylvestris', 'scots-pine.jpg', 'Photo by Michel Langeveld', 'https://commons.wikimedia.org/wiki/File:Pinus_sylvestris_172179504.jpg'],
    ['Common Beech', 'Fagus sylvatica', 'common-beech.jpg', 'Photo by Michel Langeveld', 'https://commons.wikimedia.org/wiki/File:Fagus_sylvatica_95509787.jpg'],
    ['Lavendar (English)', 'Lavandula augustifolia', 'lavendar.jpg', 'Photo by Amanda Slater', 'https://commons.wikimedia.org/wiki/File:LavendelHidcote11.jpg'],
    ['Rosemary', 'Rosmarinus officinalis', 'rosemary.jpg', 'Photo by Filo gèn', 'https://commons.wikimedia.org/wiki/File:Rosmarinus_officinalis_(Lamiaceae)_01.jpg'],
    ['Sage', 'Salvia officinalis', 'sage.jpg', 'Photo by Tiefnig0815', 'https://commons.wikimedia.org/wiki/File:Sage0.jpg'],
    ['Tansy', 'Tanacetum vulgare', 'tansy.jpg', 'Photo by Alexis', 'https://commons.wikimedia.org/wiki/File:Tanacetum_vulgare_107659014.jpg'],
    ['Basil', 'Ocimum basilicum', 'basil.jpg', 'Photo by Cliff Hutson', 'https://commons.wikimedia.org/wiki/File:Basil_(Ocimum_basilicum).jpg'],
    ['Chamomile', 'Matricaria recutita', 'chamomile.jpg', 'Photo by H. Zell', 'https://commons.wikimedia.org/wiki/File:Matricaria_recutita_001.JPG'],
    ['Curry Plant', 'Helichrysum italicum', 'curry-plant.jpg', 'Photo by Ghislain118', 'https://commons.wikimedia.org/wiki/File:Helichrysum_italicum_1.jpg'],
    ['Dill', 'Anethum graveolens', 'dill.jpg', 'Photo by Burkhard Mücke', 'https://commons.wikimedia.org/wiki/File:Dill_(Anethum_graveolens).jpg'],
    ['Feverfew', 'Tanacetum parthenium', 'feverfew.jpg', 'Photo by Zeynel Cebeci', 'https://commons.wikimedia.org/wiki/File:Tanacetum_parthenium_-_Feverfew_-_Beyaz_Papatya_1.jpg'],
    ['Feverfew', 'Tanacetum parthenium', 'feverfew2.jpg', 'Photo by Steffen Heinz', 'https://commons.wikimedia.org/wiki/File:Tanacetum_parthenium_Bl%C3%BCten.JPG'],
    ['Salad Burnet', 'Sanguisorba minor', 'salad-burnet.jpg', 'Photo by Daniel Ballmer', 'https://commons.wikimedia.org/wiki/File:Sanguisorba_minor_Knospen.jpg'],
    ['Winter Savory', 'Satureja montana', 'winter-savory.jpg', 'Photo by Agnieszka Kwiecień, Nova', 'https://commons.wikimedia.org/wiki/File:Satureja_montana_Cz%C4%85ber_g%C3%B3rski_2007-08-11_03.jpg'],
    ['Ramsoms', 'Allium Ursinum', 'ramsoms.jpg', 'Photo by Robert Flogaus-Faust', 'https://commons.wikimedia.org/wiki/File:Allium_ursinum_2_RF.jpg'],
    ['Scarlet Pimpernel', 'Anagallis arvensis', 'scarlet-pimpernel.jpg', 'Photo by Rosser1954', 'https://commons.wikimedia.org/wiki/File:The_Scarlet_Pimpernel_(Anagallis_arvensis)._Bennane_Cave,_South_Ayrshire.jpg'],
    ['Charlock', 'Sinapis arvensis', 'charlock.jpg', 'Photo by Hectonichus', 'https://commons.wikimedia.org/wiki/File:Brassicaceae_-_Sinapis_arvensis_(3).JPG'],
    ['Cleavers', 'Galium aparine', 'cleavers.jpg', 'Photo by lazarus', 'https://commons.wikimedia.org/wiki/File:Galium_aparine_101084722.jpg'],
    ['Cleavers', 'Galium aparine', 'cleavers2.jpg', 'Photo by Valentin Hamon', 'https://commons.wikimedia.org/wiki/File:Galium_aparine_106555380.jpg'],
    ['Speedwell', 'Veronica sp.', 'speedwell.jpg', 'Photo by Sukkoria', 'https://commons.wikimedia.org/wiki/File:WLE2019_-_Ile_du_Girard_-_Veronica_%3F%3F_01.jpg'],
    ['Hairy Bittercress', 'Cardamine hirsute', 'hairy-bittercress.jpg', 'Photo by Katja Schulz', 'https://commons.wikimedia.org/wiki/File:Hairy_Bittercress_(32977478722).jpg'],
    ['Groundsel', 'Senecio vulgaris', 'groundsel.jpg', 'Photo by Jacques Neuberg', 'https://commons.wikimedia.org/wiki/File:Senecio_vulgaris_187898961.jpg'],
    ['Red Dead Nettle', 'Lamium purpureum', 'red-dead-nettle.jpg', 'Photo by Robert Flogaus-Faust', 'https://commons.wikimedia.org/wiki/File:Lamium_purpureum_RF.jpg'],
    ['Fat Hen', 'Chenopodium album', 'fat-hen.jpg', 'Photo by Rasbak', 'https://commons.wikimedia.org/wiki/File:Melganzenvoet_bloeiwijze_Chenopodium_album.jpg'],
    ['Red Shank', 'Persicaria maculosa Grey', 'red-shank.jpg', 'Photo by Syp', 'https://commons.wikimedia.org/wiki/File:Persicaria_maculosa_001.JPG'],
    ['Red Campion', 'Silene dioica', 'red-campion.jpg', 'Photo by Krzysztof Ziarnek', 'https://commons.wikimedia.org/wiki/File:Silene_dioica_kz13.jpg'],
    ['Broccoli', 'Brassica oleracea Italica', 'broccoli.jpg', 'Photo by Sanjay Acharya', 'https://commons.wikimedia.org/wiki/File:Broccoli_Flowers.jpg'],
    ['Kale', 'Brassica oleracea Acephala', 'kale.jpg', 'Photo by Rasbak', 'https://commons.wikimedia.org/wiki/File:Brassica_oleracea_convar._acephala_var._laciniata_%27Westlandse_winter%27,_boerenkool_%27Westlandse_winter%27.jpg'],
    ['Kohl Rabi', 'Brassica oleracea Gongylodes', 'kohl-rabi.jpg', 'Photo by Rasbak', 'https://commons.wikimedia.org/wiki/File:Koolrabi_(Brassica_oleracea_convar._acephala_alef._var._gongylodes).jpg'],
    ['Potato', 'Solanum tuberosum', 'potato.jpg', 'Photo by Ordercrazy', 'https://commons.wikimedia.org/wiki/File:Solanum_tuberosum_Rosenerdling.jpg'],
    ['Tomato', 'Solanum lycopersicum', 'tomato.jpg', 'Photo by Juan Carlos Fonseca Mata', 'https://commons.wikimedia.org/wiki/File:Flower_of_Solanum_lycopersicum.jpg'],
    ['Aubergine', 'Solanum melongena', 'aubergine.jpg', 'Photo by Risesian', 'https://commons.wikimedia.org/wiki/File:Bunga_pada_tanaman_Solanum_melongena.jpg'],
    ['Florence Fennel', 'Foeniculum vulgare', 'florence-fennel.jpg', 'Photo by Eugene Zelenko', 'https://commons.wikimedia.org/wiki/File:Foeniculum_vulgare03.jpg'],
    ['Spring Onion', 'Allium cepa', 'spring-onion.jpg', 'Photo by Judgefloro', 'https://commons.wikimedia.org/wiki/File:JfCamachilesMabalacatSpringOnions364fvf.JPG'],
    ['Shallot', 'Allium cepa', 'shallot.jpg', 'Source', 'https://commons.wikimedia.org/wiki/File:Shallots.jpg'],
    ['Pumpkin', 'Cucurbita maxima', 'pumpkin.jpg', 'Photo by Varun Pabrai', 'https://commons.wikimedia.org/wiki/File:Cucurbita_maxima-2.JPG'],
    ['Cucumber', 'Cucumis sativus', 'cucumber.jpg', 'Photo by H. Zell', 'https://commons.wikimedia.org/wiki/File:Cucumis_sativus_0001.JPG'],
    ['Lettuce', 'Lactuca sativa', 'lettuce.jpg', 'Photo by Selena von Eichendorf', 'https://commons.wikimedia.org/wiki/File:Lactuca_sativa-whole_plant_top.JPG'],
    ['Courgette', 'Cucurbita pepo', 'courgette.jpg', 'Photo Forest & Kim Starr', 'https://commons.wikimedia.org/wiki/File:Starr_080815-9759_Cucurbita_pepo.jpg'],
    ['Hazel', 'Corylus avellana', 'hazel.jpg', 'Photo by Krzysztof Ziarnek', 'https://commons.wikimedia.org/wiki/File:Corylus_avellana_kz10.jpg'],
    ['Hawthorn / Whitethorn', 'Carategus monogyna', 'hawthorn.jpg', 'Photo by Horst J. Meuter', 'https://commons.wikimedia.org/wiki/File:Hawthorn_-_Eynatten_-_Belgium.jpg'],
    ['Common Alder', 'Alnus glutinosa', 'alder.jpg', 'Photo by SABENCIA Bertu Ordiales', 'https://commons.wikimedia.org/wiki/File:Alnus_glutinosa._Umeru.jpg'],
    ['Italian Alder', 'Alnus cordata', 'italian-alder.jpg', 'Photo by SABENCIA Guillermo César Ruiz', 'https://commons.wikimedia.org/wiki/File:Alnus_cordata._Umeru_italianu.jpg'],
    ['Ash', 'Fraxinus excelsior', 'ash.jpg', 'Photo by MPF', 'https://commons.wikimedia.org/wiki/File:Fraxinus_excelsior.jpg'],
    ['Silver Birch', 'Betula pendula', 'silver-birch.jpg', 'Photo by Andrew Butko', 'https://commons.wikimedia.org/wiki/File:Betula_pendula_in_Sedovo_1.jpg'],
    ['Silver Birch', 'Betula pendula', 'silver-birch2.jpg', 'Photo by Nefronus', 'https://commons.wikimedia.org/wiki/File:Betula_pendula_leaves.jpg'],
    ['Spindle Tree', 'Euonymus europaeus', 'spindle-tree.jpg', 'Photo by Michel Langeveld', 'https://commons.wikimedia.org/wiki/File:Euonymus_europaeus_149400211.jpg'],
    ['Blackthorn', 'Prunus spinosa', 'blackthorn.jpg', 'Photo by Wilhelm Zimmerling', 'https://commons.wikimedia.org/wiki/File:Ruhland,_Grenzstr._gegen%C3%BCber_Hausnr._3,_Schlehe_(Prunus_spinosa),_Fr%C3%BCchte_am_Zweig,_Sommer,_04.jpg'],
    ['Sea buckthorn', 'Hippophae rhamnoides', 'sea-buckthorn.jpg', 'Photo by Stephen James McWilliam', 'https://commons.wikimedia.org/wiki/File:Hippophae_rhamnoides_89218410.jpg'],
    ['Willow', 'Salix spp.', 'willow.jpg', 'Photo by John Tann', 'https://commons.wikimedia.org/wiki/File:Weeping_Willow_leaf_(3212275000).jpg'],
    ['Elder', 'Sambucus nigra', 'elder.jpg', 'Photo by Björn S.', 'https://commons.wikimedia.org/wiki/File:Sambucus_nigra_(42477369502).jpg'],
    ['Dog Rose', 'Rosa canina', 'dog-rose.jpg', 'Photo by Niepokój Zbigniew', 'https://commons.wikimedia.org/wiki/File:Dzika_r%C3%B3%C5%BCa_(Rosa_canina)_02.jpg'],
    ['Holly', 'Hex aquifolium', 'holly.jpg', 'Photo by Roger Jones', 'https://commons.wikimedia.org/wiki/File:Holly_Tree_in_the_wood_-_geograph.org.uk_-_2662378.jpg'],
    ['Apple', 'Malus domestica', 'apple.jpg', 'Photo by AnRo0002', 'https://commons.wikimedia.org/wiki/File:20150423Malus_domestica8.jpg'],
    ['Marjoram', 'Origanum majorana', 'marjoram.jpg', 'Photo by Forest & Kim Starr', 'https://commons.wikimedia.org/wiki/File:Starr_070906-8861_Origanum_majorana.jpg'],
    ['Lemon Balm', 'Melissa officinalis', 'lemon-balm.jpg', 'Photo by Killarnee', 'https://commons.wikimedia.org/wiki/File:Lemon_balm_4.jpg'],
    ['Lovage', 'Levisticum officinale', 'lovage.jpg', 'Photo by Mikrolit', 'https://commons.wikimedia.org/wiki/File:Levisticum_officinale-planto.jpg'],
    ['Parsley', 'Petroselinum crispum', 'parsley.jpg', 'Photo by Roula30', 'https://commons.wikimedia.org/wiki/File:%CE%9C%CE%B1%CE%B9%CE%BD%CF%84%CE%B1%CE%BD%CF%8C%CF%82_2681Petroselinum_crispum.JPG'],
    ['Chives', 'Allium schoenoprasum', 'chives.jpg', 'Photo by Роман Рябенко', 'https://commons.wikimedia.org/wiki/File:Bumblebee_on_chives_in_Glastonbury_Abbey_garden.jpg'],
    ['Bay', 'Laurus nobilis', 'bay.jpg', 'Photo by Agnieszka Kwiecień', 'https://commons.wikimedia.org/wiki/File:Laurus_nobilis_Wawrzyn_szlachetny_2019-05-26_01.jpg'],
    ['Mint', 'Mentha spp.', 'mint.jpg', 'Photo by Commonists', 'https://commons.wikimedia.org/wiki/File:Mint_leaves_(Mentha_spicata).jpg'],
    ['Coriander', 'Coriandrum sativum', 'coriander.jpg', 'Photo by David J. Stang', 'https://commons.wikimedia.org/wiki/File:Coriandrum_sativum_Santo_0zz.jpg'],
    ['Fennel', 'Foeniculum vulgare', 'fennel.jpg', 'Photo by 4028mdk09', 'https://commons.wikimedia.org/wiki/File:Foeniculum_vulgare_Kurpfalzhof_Oktober_2011.JPG'],
    ['Thyme', 'Thymus vulgaris', 'thyme.jpg', 'Photo by David J. Stang', 'https://commons.wikimedia.org/wiki/File:Thymus_vulgaris_4zz.jpg'],
    ['Comfrey', 'Symphytum officinale', 'comfrey.jpg', 'Photo by Michel Langeveld', 'https://commons.wikimedia.org/wiki/File:Symphytum_officinale_129048686.jpg'],
    ['Yarrow', 'Achillea millefolium', 'yarrow.jpg', 'Photo by Alexis', 'https://commons.wikimedia.org/wiki/File:Achillea_millefolium_104720956.jpg'],
    ['Borage', 'Borago officinalis', 'borage.jpg', 'Photo by Ian Alexander', 'https://commons.wikimedia.org/wiki/File:GT_Borage.jpg'],
    ['Borage', 'Borago officinalis', 'borage.jpg', 'Photo by Jeffdelonge', 'https://commons.wikimedia.org/wiki/File:Borago_officinalis1.jpg'],
    ['Creeping Thistle', 'Cirisum arvense', 'creeping-thistle.jpg', 'Photo by Ivar Leidus', 'https://commons.wikimedia.org/wiki/File:Cirsium_arvense_-_p%C3%B5ldohakas.jpg'],
    ['Bramble / Blackberry', 'Rubus fruticosus', 'bramble.jpg', 'Photo by Rasbak', 'https://commons.wikimedia.org/wiki/File:Gewone_braam_(Rubus_fruticosus.jpg'],
    ['Creeping Buttercup', 'Ranunculus repens', 'buttercup.jpg', 'Photo by Karelj', 'https://commons.wikimedia.org/wiki/File:Ranunculus_repens_prysky%C5%99n%C3%ADk_plaziv%C3%BD_2.jpg'],
    ['Rushes', 'Juncus spp.', 'rushes.jpg', 'Photo by M J Richardson', 'https://commons.wikimedia.org/wiki/File:Rushes_at_Powhillon_-_geograph.org.uk_-_1910115.jpg'],
    ['Field Bindweed', 'Convolvulus arvensis', 'bindweed.jpg', 'Photo by Sarah Vermaak', 'https://commons.wikimedia.org/wiki/File:Convolvulus_arvensis_153365418.jpg'],
    ['Couch Grass', 'Elymus repens', 'couch-grass.jpg', 'Photo by Matt Lavin', 'https://commons.wikimedia.org/wiki/File:Elymus_repens_(3900638448).jpg'],
    ['Dock', 'Rumex spp.', 'dock.jpg', 'Photo by Krzysztof Ziarnek', 'https://commons.wikimedia.org/wiki/File:Rumex_obtusifolius_kz06.jpg'],
    ['Dandelion', 'Taraxacum officinale', 'dandelion.jpg', 'Photo by Agnieszka Kwiecień', 'https://commons.wikimedia.org/wiki/File:Taraxacum_officinale_100.jpg'],
    ['Stinging Nettle', 'Urtica dioica', 'nettle.jpg', 'Photo by Georgy Vinogradov', 'https://commons.wikimedia.org/wiki/File:Urtica_dioica_139756771.jpg'],
    ['Horsetail', 'Equisetum arvense', 'horsetail.jpg', 'Photo by botany08', 'https://commons.wikimedia.org/wiki/File:Equisetum_arvense_126536963.jpg'],
    ['Japanese Knotweed', 'Fallopia japonica', 'japanese-knotweed.jpg', 'Photo by Wilma Verburg', 'https://commons.wikimedia.org/wiki/File:Fallopia_japonica01.jpg'],
    ['Ground Elder', 'Aegopodium podagraria', 'ground-elder.jpg', 'Photo by Alexis', 'https://commons.wikimedia.org/wiki/File:Aegopodium_podagraria_189842810.jpg'],
    ['Smooth Sow Thistle', 'Sonchus oleraceus', 'sow-thistle.jpg', 'Photo by Krzysztof Ziarnek', 'https://commons.wikimedia.org/wiki/File:Sonchus_oleraceus_kz02.jpg'],
    ['Foxglove (Common)', 'Digitalis purpurea', 'foxglove.jpg', 'Photo by Adrienne Pinsoneault', 'https://commons.wikimedia.org/wiki/File:Digitalis_purpurea_117294774.jpg'],
    ['Bluebell', 'Hyacinthoides non-scripta', 'bluebell.jpg', 'Photo by Eddi Laumanns', 'https://commons.wikimedia.org/wiki/File:German_Hyacinthoides_non-scripta.jpg'],
    ['Primrose (Common)', 'Primula vulgaris', 'primrose.jpg', 'Photo by Zeynel Cebeci', 'https://commons.wikimedia.org/wiki/File:Primula_acaulis_subsp._acaulis_(%C3%87uha_%C3%A7i%C3%A7e%C4%9Fi)_03.jpg'],
    ['Herb Robert', 'Geranium robertianum', 'herb-robert.jpg', 'Photo by Robert Flogaus-Faust', 'https://commons.wikimedia.org/wiki/File:Geranium_robertianum_2_RF.jpg'],
    ['Montbretia', 'Crocosmia x crocosmiiflora', 'montbretia.jpg', 'Photo by Forest & Kim Starr', 'https://commons.wikimedia.org/wiki/File:Starr_050817-3944_Crocosmia_x_crocosmiiflora.jpg'],
    ['Meadowsweet', 'Filipendula ulmaria', 'meadowsweet.jpg', 'Photo by Zeke Marshall', 'https://commons.wikimedia.org/wiki/File:Filipendula_ulmaria_194793245.jpg'],
    ['Sweetcorn', 'Zea mays', 'sweetcorn.jpg', 'Photo by SABENCIA Guillermo César Ruiz', 'https://commons.wikimedia.org/wiki/File:Zea_mays._Ma%C3%ADz.jpg'],
    ['French Beans', 'Phaseolus vulgaris', 'french-beans.jpg', 'Photo by DenesFeri', 'https://commons.wikimedia.org/wiki/File:Fabales_-_Phaseolus_vulgaris_2.jpg'],
    ['French Beans', 'Phaseolus vulgaris', 'french-beans2.jpg', 'Photo by Forest & Kim Starr', 'https://commons.wikimedia.org/wiki/File:Starr_081031-0365_Phaseolus_vulgaris.jpg'],
    ['Runner Bean', 'Phaseolus coccineus', 'runner-bean.jpg', 'Photo by Kolforn (Wikimedia)', 'https://commons.wikimedia.org/wiki/File:-2019-07-02_Runner_beans_plants_in_flower_(Phaseolus_coccineus),_Trimingham,_Norfolk_(2).JPG'],
    ['Pea', 'Pisum sativum', 'pea.jpg', 'Photo by Kolforn', 'https://commons.wikimedia.org/wiki/File:-2019-07-20_Garden_pea_(Pisum_sativum)_in_flower,_Trimingham.JPG'],
    ['Cabbage', 'Brassica oleracea Capitata', 'cabbage.jpg', 'Photo by Rasbak', 'https://commons.wikimedia.org/wiki/File:Brassica_oleracea_convar._capitata_var._alba,_spitskool_(1).jpg'],
    ['Brussel Sprout', 'Brassica oleracea Gemmifera', 'brusselsprout.jpg', 'Photo by Nick Saltmarsh', 'https://commons.wikimedia.org/wiki/File:Brussels_sprouts_(4103982312)_(2).jpg'],
];

export default plantsdb;
