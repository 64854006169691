import React from 'react';
import './App.css';
import ClickReveal from './ClickReveal.js';
import plantsdb from './plantsdb.js';


function PlantPhoto(props) {
  const className = props.flipped ? "plantPhoto flipped" : "plantPhoto";
  return (
    <img className={className} src={props.src} onClick={props.onClick} alt="Plant for identification" />
  );
}


class App extends React.Component {
  constructor(props) {
    super(props);
    this.handleNextPlant = this.handleNextPlant.bind(this);
    this.handleRevealLatin = this.handleRevealLatin.bind(this);
    this.handleRevealCommon = this.handleRevealCommon.bind(this);

    this.plants = this.shuffle(plantsdb);
    this.state = {
        plantIndex: this.initialPlantIndex(),
        imageFlipped: this.shouldFlipImage(),
        showingCommon: false,
        showingLatin: false,
    };
  }

  initialPlantIndex() {
    if (window.location.hash.length > 0) {
      const requestedCommonName = window.location.hash.substring(1, window.location.hash.length).toLowerCase();
      for(let i = 0; i < this.plants.length; i++) {
        if (requestedCommonName === this.plants[i][0].toLowerCase()) {
          return i;
        }
      }
    }
    return 0;
  }

  shuffle(items) {
    return items.sort((_a, _b) => 0.5 - Math.random());
  }

  currentPlant() {
    const plant = this.plants[this.state.plantIndex];
    let licenseText = 'Images sourced from WikiCommons, under ShareAlike license';
    let licenseLink = 'https://creativecommons.org/licenses/by-sa/3.0/deed.en';
    if (plant.length > 3) {
      licenseText = plant[3];
      licenseLink = plant[4];
    }
    return {
      'english': plant[0],
      'latin': plant[1],
      'image': `/plantdb/${plant[2]}`,
      licenseText,
      licenseLink,
    };
  }

  shouldFlipImage() {
    return Math.random() > 0.8;
  }

  canSeeNextPlant() {
    return this.state.showingCommon && this.state.showingLatin;
  }

  handleNextPlant() {
    if (!this.canSeeNextPlant()) {
      return;
    }

    this.setState({
      plantIndex: this.nextPlantIndex(),
      imageFlipped: this.shouldFlipImage(),
      showingCommon: false,
      showingLatin: false,
    });
  }

  nextImageSrc() {
    const plant = this.plants[this.nextPlantIndex()];
    return `/plantdb/${plant[2]}`;
  }

  nextPlantIndex() {
    let nextIndex = this.state.plantIndex + 1;
    if (nextIndex >= this.plants.length) {
      nextIndex = 0;
    }
    return nextIndex;
  }

  handleRevealCommon() {
    this.setState({showingCommon: true});
  }

  handleRevealLatin() {
    this.setState({showingLatin: true});
  }

  render() {
    const plant = this.currentPlant();
    const instructionsText = this.canSeeNextPlant() ? "Tap the photo to see the next plant" : null;
    const licenseLink = this.canSeeNextPlant() ? <a className="licenseLink" href={plant.licenseLink}>{plant.licenseText}</a> : null;
    return (
      <div className="App">
        <img className="preload" src={this.nextImageSrc()} alt="hidden preload" />
        <div className="titleBar">
          <h1>I know that plant!</h1>
        </div>
        <div className="content">
          <PlantPhoto src={plant.image} onClick={this.handleNextPlant} flipped={this.state.imageFlipped} />
          <div className="nameReveal">
            <ClickReveal language="common" name={plant.english} onClick={this.handleRevealCommon} revealed={this.state.showingCommon} />
            <ClickReveal language="Latin" name={plant.latin} onClick={this.handleRevealLatin} revealed={this.state.showingLatin} />
          </div>
          <p className="instructions">{instructionsText}</p>
          {licenseLink}
        </div>
      </div>
    );
  }
}

export default App;
