function ClickReveal(props) {
    const label = props.revealed ? props.name : `Tap for ${props.language} name`;
    const className = props.revealed ? 'revealed' : 'notRevealed';
    return (
        <div onClick={props.onClick} className={className}>
            {label}
        </div>
    )
}

export default ClickReveal;
